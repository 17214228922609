@import "~src/assets/scss/_variables";

.swiper {
  overflow: visible;
}

.pcb-container {

  // background-image: url('/assets/imgx/bundle-5-secret-chats-bg.png');
  background: linear-gradient(180deg,  #3AFCAA 0%, #0F45AE  100%);
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  height: auto;
  min-height: 200px;
  padding: 3px;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  margin-bottom: 24px;
  position: relative;
  // padding: 0; //  FOR BLACK FRIDAY ONLY

  min-height: 200px;

  * {
    font-family: 'Inter', sans-serif;
  }

  h2 {
    font-size: 29px;
    font-weight: 900;
    line-height: 83.02%;
    text-transform: uppercase;
    color: #fff;
    span {
      color: #EEFF2E;
    }
  }


  .pcb-icons-box{
    position: absolute;
    right: 12px;
    top: -5px;
    height: 100%;
    width: 200px;
    transform: scale(0.9);
    .pcb-icon{
      position: absolute;
      width: 85px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      // background: linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
      border: 3px solid #fff;
      box-shadow: 0 3px 5px rgba($color: $primary-color, $alpha: 0.3);
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;

      }

      &.pcb-icon-1 {
        top: 8px;
        left: 37px;
      }
      &.pcb-icon-2 {
        top: 12px;
        left: 104px;
      }
      &.pcb-icon-3 {
        top: 82px;
        left: 118px;
      }
      &.pcb-icon-4 {
        top: 113px;
        left: 54px;
      }
      &.pcb-icon-5 {
        top: 64px;
        left: -2px;
      }
    }
  }


  .sale-block {
    position: absolute;
    background: url(~src/assets/img/offer-badge.png) no-repeat;
    width: 83px;
    height: 64px;
    bottom: -10px;
    left: -7px;
    transform: scale(1.2);
    .sale, .percents {
      position: absolute;
      transform: rotate(20deg);
      color: #fff;
      &.sale {
        top: 13px;
        left: 30px;
        font-weight: 900;
      }
      &.percents {
        top: 27px;
        left: 24px;
        font-weight: 900;
        font-size: 20px;
      }
    }
    .ring {
      position: absolute;
      top: 8px;
      left: 1px;
      width: 16px;
      height: 16px;
      background: url(~src/assets/img/offer-ring.png) no-repeat;
    }

  }

  .price-block {
    background: #fff;
    height: 26px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .old-price {
      width: 55%;
      background: #79DA18;
      border-radius: 13px;
      text-align: center;
      color: #fff;
      line-height: 26px;
      position: relative;
    }
    .new-price {
      text-align: center;
      width: 45%;
      line-height: 26px;
    }

    button {
      background-color: #fff;
    }
  }


  .girls-names-block, .description-block {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    a {
      color: #fff;
    }
  }




    .header-text-block {
      position: absolute;
      top: 17px;
      left: 20px;
      width: 55%;

    }

    .price-block {

      position: absolute;
      bottom: 10px;
      left: 140px;
      transform: scale(1.2);

      .old-price {
        width: 110px;
      }
      .new-price {
        width: 90px;
      }

    }

    &.slider-small {
      min-height: 100px;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 15px;
      overflow: hidden;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      border-radius: 11px;
      box-shadow: none;
      margin-bottom: 5px;
      .header-text-block{
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        h2 {
          font-size: 20px;
          margin: 0;
          text-align: center;
        }
      }
      .price-block {
        position: relative;
        left: 0;
        bottom: 0;
        transform: scale(1);
      }
      .pcb-icons-box {
        left: 0;
        top: 0;
        transform: scale(1);
        width: 100%;
        right: auto;



        .pcb-icon{
          transform: scale(0.6);
          &.pcb-icon-1 {
            top: -12px;
            left: -9px;
          }
          &.pcb-icon-2 {
            top: 21px;
            left: 16px;
          }
          &.pcb-icon-3 {
            top: -18px;
            left: auto;
            right: 9px;
          }
          &.pcb-icon-4 {
            top: 8px;
            left: auto;
            right: -18px;
          }
          &.pcb-icon-5 {
            top: 26px;
            left: auto;
            right: 18px;
          }
        }


      }
      .swiper-pagination-bullets {
        display: none !important;
      }
    }



    &.banner-slider-container {
      max-width: 300px;
      background: none;
      box-shadow: none;
      border: none;
      overflow: hidden;
      border-radius: 0;
    }
  }


  @media (max-width: 500px) {
    .pcb-container {
      .pcb-icons-box {
        right: -8px;
        transform: scale(0.8);
      }
      .header-text-block {
        left: 10px;
        width: 60%;
      }
    }

  }
  @media (max-width: 470px) {
    .pcb-container {
      min-height: 180px;
      .header-text-block {
        top: 10px;
        h2 {
          font-size: 26px;
        }
      }
      .price-block {
        left: 100px;
        transform: scale(1);
      }
      .sale-block {
        transform: scale(1);
        left: -12px;
        bottom: -15px;
      }
    }
  }
  @media (max-width: 420px) {
    .pcb-container {

      min-height: 160px;
      min-height: 160px;
      .header-text-block {
        top: 0px;
        width: 55%;
        h2 {
          font-size: 22px;
        }
        .subtitle {
          font-size: 12px;
        }
      }
      .pcb-icons-box {
        right: -21px;
        top: -20px;
        transform: scale(0.7);
      }
      .price-block {
        left: 90px;
        transform: scale(1);
        .old-price {
          width: 80px;
          .red-cross-line {
            left: 20px;
          }
        }
        .new-price {
          width: 80px;
        }
      }
    }

  }

  @media (max-width: 370px) {
    .pcb-container {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      .sale-block {
        bottom: -12px;
        left: -7px;
        .ring {
          display: none;
        }
      }
    }
  }



  .black-friday-promo{
    background: #000;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    border-radius: inherit;
    overflow: hidden;
    .bf-left, .bf-right {
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .bf-left {
      background-image: url(~src/assets/imgx/black-friday-left.png);
      left: -25%;
      background-position-x: center;
    }
    .bf-right {
      background-image: url(~src/assets/img/black-friday-right.png);
      right: -27%;
      background-position-x: center;
    }
  }





  .slider-modal {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    .offer-banner {
      display: flex;
      flex-direction: column;
      .banner-top {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        border-radius: 25px 25px 0 0;

        h2 {
          padding: 10px 20px;
          text-align: center;
        }


        .pcb-icons-box {
            position: relative;
            width: 250px;
            transform: scale(1);
            margin-top: 0;
            height: 163px;
            left: 0;
            top: 0;
            .pcb-icon {
              border-radius: 9px;
              overflow: hidden;

              img {
                border-radius: 0;
                min-height: 80px;
                min-width: 80px;
                width: auto;
                height: auto;
              }

              &.pcb-icon-1 {
                top: 18px;
                left: 0;
                transform: rotate(-23deg);
              }
              &.pcb-icon-2 {
                top: 0px;
                left: 82px;
                transform: rotate(4deg);
              }
              &.pcb-icon-3 {
                top: 14px;
                left: auto;
                right: 0;
                transform: rotate(26deg);
              }
              &.pcb-icon-4 {
                top: 84px;
                left: 39px;
                transform: rotate(-16deg);
              }
              &.pcb-icon-5 {
                top: 81px;
                left: auto;
                right: 43px;
                transform: rotate(11deg);
              }
            }
          }
          .sale-block {
            position: absolute;
            bottom: -39px;
            left: -2px;
          }
      }
      .banner-bottom {
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-radius: 0 0 25px 25px;

        .girls-names-block {
          margin-top: 50px;
          margin-bottom: 20px;
          text-align: center;
          padding: 0 30px;
          color: #515151;
          font-size: 15px;
          font-weight: 600;
          a {
            color: #8D8D8D;
          }
        }
        .price-block {
          position: relative;
          left: auto;
          margin-top: 10px;
          margin-bottom: 20px;
          width: 80%;
          border: 4px solid #EEEEEE;
          box-sizing: content-box;
          height: 30px;
          border-radius: 17px;
          .old-price, .new-price {
            line-height: 30px;
          }
        }
      }
    }

    &.offer-5-chats {
      background: linear-gradient(242deg, #3AFCAA 0%, #0F45AE 100%);
    }
    &.offer-3-chats {
      background: radial-gradient(#FCFF67, #8E1172);
    }


    &.see-five-likes {
      background: linear-gradient(355deg, #FFFFFF 50%, #F8AC66 70%, #FF007A 100%);

      .offer-banner {
        .banner-top {
          h2 {
            font-size: 36px;
            line-height: 35px;
            padding: 5px 20px;
            span {
              color: #fff;
            }
          }
          .pcb-icons-box {
            transform: scale(0.9);
            height: 108px;

            .pcb-icon {
              border-width: 4px;
              img {
                filter: blur(4px);
                transition: filter 2s;
                &.unblurredd {
                  filter: blur(0);
                }
              }
              &.pcb-icon-1 {
                top: 18px;
                left: -5px;
                transform: rotate(-25deg) scale(0.9);
              }
              &.pcb-icon-2 {
                top: 18px;
                transform: rotate(23deg) scale(0.9);
                left: auto;
                right: -5px;
              }
              &.pcb-icon-3 {
                top: 2px;
                left: 36px;
                transform: rotate(-15deg) scale(0.95);
              }
              &.pcb-icon-4 {
                top: 2px;
                left: auto;
                transform: rotate(10deg) scale(0.95);
                right: 36px
              }
              &.pcb-icon-5 {
                top: -6px;
                left: 80px;
                right: auto;
                transform: rotate(-2deg);
              }
            }
          }
        }
        .banner-bottom {
          p {
            font-size: 14px;
            font-weight: 600;
            color: #ababab;
            padding: 0 50px;
            text-align: center;
          }
          .price-block {
            position: relative;
            left: auto;
            bottom: auto;
            right: auto;
            top: auto;
          }
        }
      }
    }
    &.one-chat-and-pack {
      background: linear-gradient(325deg, #FFFFFF 50%, #0E6EA4 70%, #E90070 100%);

      .offer-banner {
        .banner-top {
          h2 {
            font-size: 36px;
          }
          .pcb-icons-box {
            width: 88px;
          }
        }
      }
    }
    &.one-cheap-chat {
      background: linear-gradient(325deg, #FFFFFF 50%, #0E6EA4 70%, #E90070 100%);

      .offer-banner {
        .banner-top {
          h2 {
            padding: 30px 20px 0;
            font-size: 40px;
          }
          .pcb-icons-box {
            width: 100px;
            .pcb-icon{
              width: 100px;
              height: 100px;
              transform: rotate(0);
              box-shadow: 6px 6px 6px rgba(44, 111, 149, 0.25);
            }
          }
          .sale-block {
            transform: scale(1.4);
            left: 1px;
            bottom: -5px;
          }
        }
        .banner-bottom {
          .price-block {
            width: 200px;
            margin-bottom: 5px;
            .old-price {
              width: 50%;
            }
            .new-price {
              width: 50%;
              button {
                font-size: 17px;
                font-weight: 700;
                color: #005033;
              }
            }
          }
          .girls-names-block {
            margin-top: 30px;
            margin-bottom: 35px;
            color: #8D8D8D;
            font-size: 15px;
            line-height: 25px;
            font-weight: 400;
            .bold-name {
              color: #4E4E4E;
              font-size: 24px;
              font-weight: 900;
            }
          }
        }
      }
    }
  }




  .one-chat-and-pack {
    background: linear-gradient(314deg,  #FFFFFF 0%, #9BE7FF 9%, #0063BF 100%);
  }


.gems-scatter {
  position: absolute;
  transform: scale(1.7);
  top: 30px;
  left: 10px;
}



.offer-banner-container {
  &.one-chat-and-pack {
    background: linear-gradient(352deg, #FFFFFF 42%, #0E6EA1 78%, #E90070 100%);

    .offer-banner {
      .banner-top {
        h2 {
          font-size: 36px;
        }
        .pcb-icons-box {
          width: 88px;
        }
      }
    }
  }
  &.one-cheap-chat,
  &.one-chat-and-pack {
    background: linear-gradient(352deg, #FFFFFF 42%, #0E6EA1 78%, #E90070 100%);

    .header-text-block {
      top: 16px;
      width: calc(100% - 67px);
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 24px;
        font-weight: 900;
      }

      .girls-names-block {
        text-align: center;
        margin-top: 5px;
        color: #616161;
        font-size: 11px;
        font-weight: 400;
        a {
          font-size: 16px;
          font-weight: 900;
          color: #616161;
        }
      }
    }

    .sale-block {
      bottom: 30px;
    }

    .pcb-icons-box {
      width: 88px;
      border-radius: 9px;
      transform: scale(1) rotate(7deg);
      right: -2px;
      top: 20px;
      width: 137px;
      .pcb-icon {
        overflow: hidden;
        border-radius: 9px;
        img {
          border-radius: 0;
        }
      }
    }

    .price-block {
      border: 4px solid #EEEEEE;
      box-sizing: content-box;
      height: 24px;
      width: 140px;
      border-radius: 17px;
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      margin-top: 10px;
      .old-price, .new-price {
        line-height: 30px;
        width: 50%;
        line-height: 24px;
        button {
          font-size: 11px;
          font-weight: 700;
          color: #005033;
        }
      }
    }

  }
}
